<template>
  <div>
    <van-empty description="暂无数据" v-if="grid.ls.length==0" />
    <div v-else>
      <van-notice-bar left-icon="volume-o" text="直接点击记录，可以修改。" />
      <van-list :finished="grid.finish" @load="loadPage" v-model="grid.loading" :immediate-check="false"
        style="padding: 10px 0px;">
        <van-cell v-model="it.TEA_NAME" v-for="it in grid.ls" :key="it.ID" @click="edit(it)">
          <template #title>
            【{{it.STEP_IDX_TYPE_NAME}}】【{{it.STEP_IDX}}】 {{it.STEP_NAME}}
          </template>
          <template #label>
            <span v-if="it.COND_VAL">条件值： {{it.COND_VAL}}</span>
          </template>
        </van-cell>
      </van-list>
    </div>

    <van-popup v-model="ppp.edit" position="bottom" round closeable>
      <van-cell-group title="修改配置内容">

        <van-field v-model="model.STEP_IDX_TYPE_NAME" label="流程类型" :readonly="model.STEP_IDX_TYPE_NAME === '系统预设'" @click="model.STEP_IDX_TYPE_NAME !== '系统预设' && (stepTypes.changeType=true)" />
        <van-popup v-model="stepTypes.changeType" position="bottom">
          <van-picker :columns="columnsType" value="key" key="key" show-toolbar @cancel="stepTypes.changeType=false" @confirm="selStepType" />
        </van-popup>


        <van-field v-model="model.STEP_IDX" label="步骤号" readonly  />
        <van-field v-model="model.STEP_NAME" label="步骤名称" :readonly="model.STEP_IDX_TYPE_NAME === '系统预设'" />
        <van-field v-model="model.COND_VAL" label="条件值" />
        
        <van-field v-model="model.TEA_NAME" label="处理人" is-link readonly @click="ppp.tea = true" />
        <van-popup v-model="ppp.tea" position="bottom">
          <van-picker :columns="tea.ls" value-key="NAME" show-toolbar @cancel="ppp.tea=false" @confirm="selTea" />
        </van-popup>
        <div style="padding: 20px 80px;">
          <van-button type="primary" size="large" icon="success" @click="submit">确定提交</van-button>
        </div>
      </van-cell-group>
    </van-popup>
  </div>
</template>
<style scoped>
</style>
<script>

export default {
  name: "conf",
  data() {
    return {
      grid:{
        sea:{
          PAGE_SIZE: 10,
          PAGE_INDEX: -1,
        },
        ls:[],
        total:0,
        loading:false,
        finish:false,
        
      },
      tea:{
        ls:[],
      },
      ppp:{
        edit:false,
        tea:false
      },
      model:{},
      columnsType: [{text:'采购单据',key:1},{text:'领用单据',key:2},{text:'借用单据',key:3}],
      stepTypes:{
        changeType:false
      }
    }
  },
  created() {
    this.filter();
    this.getTeachers();
  },
  methods:{
    filter(){
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(){
      this.grid.sea.PAGE_INDEX += 1;
      this.getList();
    },
    getList(){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getCollection({
        url: "/api/Mobile/MAT/MVActConfApi/GetList",
        data: this.grid.sea,
        finally() { self.grid.loading = false; },
        completed: function (its, n) {
          self.grid.ls.push(...its.ITEMS);
          if (self.grid.ls.length >= n) self.grid.finish = true;
        }
      })
    },
    getTeachers(){
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/Mobile/ORG/TeacherApi/GetList",
        completed(its){
          self.tea.ls=its.ITEMS;
        }
      })
    },
    edit(o){
      this.model=o;
      this.ppp.edit=true
    },
    selTea(o){
      this.model.TEA_ID=o.ID;
      this.model.TEA_NAME=o.NAME;
      this.ppp.tea=false;
    },
    selStepType(o){
      this.model.STEP_IDX_TYPE=o.key;
      this.model.STEP_IDX_TYPE_NAME=o.text;
      this.stepTypes.changeType=false;
    },
    submit(){
      let self=this;
      self.model.TEA_IDS=self.model.TEA_ID;
      
      this.whale.remote.getResult({
        url:"/api/Mobile/MAT/MVActConfApi/Save",
        data:self.model,
        finally(){},
        completed:function (){
          self.ppp.edit=false;
          self.grid.ls.map(o=>{
            if (o.ID==self.model.ID)
              Object.assign(o,self.model)
          })
        }
      })
    }
  }
}
</script>

